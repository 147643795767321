<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Please arrange each of the following steps in the correct order for the procedure for Part A
        of this experiment.
      </p>

      <drag-to-rank-input
        v-model="inputs.inputs"
        :items="choicesShuffled"
        class="mb-0"
        style="max-width: 631px"
        prepend-text="First Step"
        append-text="Last Step"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcM1A03_A4_FlowChart1',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputs: [],
      },
      choices: [
        'Measure out enough water to dissolve the salt',
        'Record temperature vs time data for at least 6 min',
        'Place the can inside the calorimeter',
        'Determine the mass of the can',
        'Transfer the salt to the calorimeter (outside of can)',
        'Reset SIM and repeat steps 1-8 for remaining salts',
        'Measure out the desired amount of salt in weigh boat',
        'Add approximately 100 g of water to the can',
        'Add the water to the calorimeter to dissolve salt',
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    choicesShuffled() {
      return seededShuffle(this.choices, this.seed);
    },
  },
};
</script>
